<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <m-card :title="$t('shared.add')">
      <v-row dense>
        <v-col cols="12" sm="6">
          <c-input v-model="model.name" :label="$t('shared.name')" :rules="'required|max:30'" />
        </v-col>

        <v-col cols="12" sm="6" class="d-flex align-center">
          <v-switch v-model="model.active" :label="$t('shared.active')" inset />
        </v-col>
      </v-row>

      <template #actions>
        <page-footer @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],
  methods: {
    submit() {
      return this.$api.payments
        .create(this.model)
        .then((res) => {
          this.model = res
          this.$showSuccess(this.$t('payments.paymentAdded'))
        })
        .catch((err) => {
          this.$showError(err)
        })
    }
  }
}
</script>
